<template>
	<span>
		<span @click="toggle">
			<slot name="reference" />
		</span>

		<a-modal v-if="isShow" class="deduction-modal" :visible="true" title="Deduction Information" width="1000px" @cancel="toggle" :footer="null">
			<a-form-model ref="formRef" :model="formData" layout="vertical">
				<span class="row-start-center" style="margin-bottom: 10px">
					<div class="switch-label" style="">Upfront Fee</div>
				</span>

				<a-row type="flex" :gutter="20">
					<a-col flex="1">
						<a-form-model-item label="Upfront Fee - collected">
							<a-statistic :value="formData.collectedUpfront" :precision="2" />
						</a-form-model-item>
					</a-col>
					<a-col flex="1">
						<a-form-model-item label="Upfront Fee - to be deducted">
							<a-statistic :value="formData.toBeDeducedUpfront" :precision="2" />
						</a-form-model-item>
					</a-col>
					<a-col flex="1">
						<a-form-model-item label="Total Upfront Fee">
							<a-statistic :value="totalUpfrontFee" :precision="2" />
						</a-form-model-item>
					</a-col>
				</a-row>

				<span v-if="!isFromIPF">
					<span class="row-start-center" style="margin-bottom: 10px">
						<div class="switch-label" style="">Liquidation</div>
					</span>

					<a-row type="flex" :gutter="20">
						<a-col flex="1">
							<a-form-model-item label="Liquidation - Amount">
								<a-statistic :value="formData.liquidation" :precision="2" />
							</a-form-model-item>
						</a-col>
						<a-col flex="1">
							<a-form-model-item label="Liquidation - Loan Registration No">
								<a-statistic :value="formData.regNo" />
							</a-form-model-item>
						</a-col>
						<a-col flex="1" />
					</a-row>

					<span class="row-start-center" style="margin-bottom: 10px">
						<div class="switch-label" style="">Other Deduction</div>
					</span>

					<a-row type="flex" :gutter="20">
						<a-col flex="1">
							<a-form-model-item label="Deduction Amount">
								<a-statistic :value="formData.otherDeduce" :precision="2" />
							</a-form-model-item>
						</a-col>
						<a-col flex="1" style="max-width: 33%">
							<a-form-model-item label="Description">
								<a-statistic :value="formData.description" />
							</a-form-model-item>
						</a-col>
						<a-col flex="1" />
					</a-row>
				</span>
			</a-form-model>
		</a-modal>
	</span>
</template>

<script>
import { Rule, cloneTargetValue } from '@/utils'
import Decimal from 'decimal.js'

export default {
	props: {
		upfrontReduction: {
			type: Object
		},
		isFromIPF: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isShow: false,
			formData: {
				upfrontSwitch: 0, //upfront开关，1开，0关
				collectedUpfront: 0, //已收取的upfront费用
				toBeDeducedUpfront: 0, //待收取的upfront费用
				liquidationSwitch: 0, //上一笔贷款待还金额开关
				liquidation: 0, //上一笔贷款待还金额
				regNo: '', //上一笔贷款车牌号
				otherSwitch: 0, //其他费用开关
				otherDeduce: 0, //其他费用金额
				description: '' //其他费用描述
			}
		}
	},
	computed: {
		totalUpfrontFee() {
			return Decimal(this.formData.collectedUpfront || 0)
				.add(Decimal(this.formData.toBeDeducedUpfront || 0))
				.toFixed(2)
		}
	},
	methods: {
		toggle() {
			this.isShow = !this.isShow
			if (this.isShow) {
				cloneTargetValue(this.formData, this.upfrontReduction)
				this.formData.toBeDeducedUpfront ?this.formData.toBeDeducedUpfront: 0
				this.formData.collectedUpfront ?this.formData.collectedUpfront: 0
			}
		},
		onSwitchChange(key, value) {
			this.formData[key] = value ? 1 : 0
			switch (key) {
				case 'upfrontSwitch':
					if (!value) {
						this.formData.collectedUpfront = 0
						this.formData.toBeDeducedUpfront = 0
					}
					break
				case 'liquidationSwitch':
					if (!value) {
						this.formData.liquidation = 0
						this.formData.regNo = ''
					}
					break
				case 'otherSwitch':
					if (!value) {
						this.formData.otherDeduce = 0
						this.formData.description = ''
					}
					break
				default:
					break
			}
		},
		async submit() {
			await this.$refs['formRef'].validate()
			this.$emit('update:upfrontReduction', this.formData)
			this.toggle()
		}
	}
}
</script>
<style lang="less" scope>
.switch-label {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.85);
	margin-right: 10px;
	font-weight: 600;
}
.deduction-modal .ant-statistic-content {
	color: rgba(0, 0, 0, 0.5);
}
</style>
