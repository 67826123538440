<template>
	<!-- 保险信息 start -->
	<div class="ant-descriptions ant-descriptions-bordered">
		<div class="ant-descriptions-title">
			{{ $t('table.applyInfo.loanApplyLabel.insurance') }}
			<a-button v-if="!addedIpf" style="margin-left: 20px" type="primary" @click="addIPF"> Add IPF</a-button>
		</div>
		<a-tabs v-model="ipfActiveKey" type="editable-card" :animated="true" style="margin-top: 20px" @edit="removeIPF" hideAdd>
			<div v-if="insuranceData[Number(ipfActiveKey)]?.newInput" slot="tabBarExtraContent" style="position: absolute; left: 150px; top: 0px; z-index: 1">
				<edit-deduction-modal :upfrontReduction.sync="insuranceData[Number(ipfActiveKey)]" isFromIPF @onSubmitSuccess="getInsTryCalculate">
					<a-button slot="reference" style="margin-left: 10px" type="primary">Edit Upfront Fee</a-button>
				</edit-deduction-modal>
			</div>
			<a-tab-pane
				:tab="'IPF ' + (insuranceData.length - index)"
				v-for="(insurInfo, index) in insuranceData"
				:key="String(index)"
				:closable="insurInfo.newInput"
			>
				<template v-if="insurInfo.newInput">
					<a-descriptions bordered size="default">
						<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.insurance')">
							<a-radio-group v-model="insurInfo.hasInsurance" style="margin: 10px 0">
								<a-radio :value="1"> Yes </a-radio>
								<a-radio :value="0"> No </a-radio>
							</a-radio-group>
							<a-form class="insurance-form" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" v-if="insurInfo.hasInsurance === 1">
								<a-form-item label="Principal Amount">
									<a-input-number :precision="2" v-model="insurInfo.insuranceAmount" :min="0" @change="getInsTryCalculate(true)" />
								</a-form-item>
								<a-form-item label="Installment Principal">
									<a-statistic
										:precision="2"
										:value="insurInfo.insuranceInstallmentPrincipal == null ? '' : insurInfo.insuranceInstallmentPrincipal.toFixed(2)"
									/>
								</a-form-item>
								<a-form-item label="Interest">
									<a-input-number
										:min="0"
										:max="100"
										:formatter="(value) => `${value}%`"
										:parser="(value) => value.replace('%', '')"
										:precision="1"
										v-model="insurInfo.insuranceInterest"
										@change="getInsTryCalculate"
									/>
								</a-form-item>
								<a-form-item label="Installment Interest">
									<a-statistic
										:precision="2"
										:value="insurInfo.insuranceInstallmentInterest == null ? '' : insurInfo.insuranceInstallmentInterest.toFixed(2)"
									/>
								</a-form-item>
								<a-form-item label="Period">
									<a-input-number :min="0" :step="1" :precision="0" v-model="insurInfo.insuranceInstallments" @change="getInsTryCalculate" />
								</a-form-item>
								<a-form-item label="Installment from 2nd month">
									<a-statistic
										:precision="2"
										:value="
											insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest == null
												? ''
												: Math.round(insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest).toFixed(2)
										"
									/>
								</a-form-item>
								<a-form-item label="Upfront Fee - Deduction">
									<a-statistic :precision="2" :value="insurInfo?.toBeDeducedUpfront" />
								</a-form-item>
								<a-form-item label="Net Disbursement Amount">
									<a-statistic :precision="2" :value="insurInfo?.netDisbursementAmount" />
								</a-form-item>
							</a-form>
						</a-descriptions-item>
					</a-descriptions>
					<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="small">
						<a-descriptions-item :span="3">
							<a-table
								:columns="supCloums"
								size="small"
								:rowKey="(record, supIndex) => supIndex"
								:dataSource="insurInfo.supplementFiles"
								:pagination="false"
								bordered
							>
								<span slot="fileName" slot-scope="text, obj">
									<a-input v-model="obj.filename" class="input_table" />
								</span>
								<span slot="file" slot-scope="obj">
									<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">
										{{ !$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : '' }}
									</a>
								</span>
								<span slot="fileRemark" slot-scope="text, obj">
									<a-input type="textarea" v-model="obj.note" :rows="4" :maxLength="300" />
								</span>
								<span slot="action" slot-scope="text, obj, supIndex">
									<a @click="DelSup(index, supIndex)">{{ $t('page.delete') }}</a>
								</span>
							</a-table>
						</a-descriptions-item>
						<a-descriptions-item>
							<UploadPreviewFile
								:uploadFilePath.sync="insurInfo.supplementFiles"
								:folderPath="$route.query.id"
								:isPreviewFile="false"
							></UploadPreviewFile>
						</a-descriptions-item>
					</a-descriptions>
				</template>
				<template v-else>
					<a-descriptions bordered v-if="insurInfo.hasInsurance === 0 || insurInfo.hasInsurance === null">
						<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.insurance')">No</a-descriptions-item>
					</a-descriptions>
					<div v-else class="ipf-yes-descriptions">
						<div class="row-center ant-descriptions-bordered ipf-wrapper">IPF</div>
						<a-descriptions bordered :column="2">
							<a-descriptions-item label="Principal Amount">
								<a-statistic :precision="2" :value="insurInfo.insuranceAmount == null ? '' : insurInfo.insuranceAmount" />
							</a-descriptions-item>
							<a-descriptions-item label="Interest">
								<a-statistic :precision="1" :value="insurInfo.insuranceInterest" suffix="%" />
							</a-descriptions-item>
							<a-descriptions-item label="Period">
								<a-statistic :value="insurInfo.insuranceInstallments" />
							</a-descriptions-item>
							<a-descriptions-item label="Installment Principal">
								<a-statistic
									:precision="2"
									:value="insurInfo.insuranceInstallmentPrincipal == null ? '' : insurInfo.insuranceInstallmentPrincipal.toFixed(2)"
								/>
							</a-descriptions-item>
							<a-descriptions-item label="Installment Interest">
								<a-statistic
									:precision="2"
									:value="insurInfo.insuranceInstallmentInterest == null ? '' : insurInfo.insuranceInstallmentInterest.toFixed(2)"
								/>
							</a-descriptions-item>
							<a-descriptions-item label="Installment from 2nd month">
								<a-statistic
									:precision="2"
									:value="
										insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest == null
											? ''
											: Math.round(insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest).toFixed(2)
									"
								/>
							</a-descriptions-item>
							<a-descriptions-item label="Upfront Fee - Deduction">
								<show-deduction-modal
									:upfrontReduction="{ collectedUpfront: insurInfo.collectedUpfront, toBeDeducedUpfront: insurInfo.toBeDeducedUpfront }"
									isFromIPF
								>
									<a slot="reference">
										<a-statistic :precision="2" :value="insurInfo.toBeDeducedUpfront" />
									</a>
								</show-deduction-modal>
							</a-descriptions-item>
							<a-descriptions-item label="Net Disbursement Amount">
								<a-statistic :precision="2" :value="insurInfo.netDisbursementAmount" />
							</a-descriptions-item>
						</a-descriptions>
					</div>
					<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="small">
						<a-descriptions-item :span="3">
							<a-table
								:columns="supCloums"
								size="small"
								:rowKey="(record, supIndex) => supIndex"
								:dataSource="insurInfo.supplementFiles"
								:pagination="false"
								bordered
							>
								<span slot="fileName" slot-scope="text, obj">
									<a-input v-model="obj.filename" class="input_table" />
								</span>
								<span slot="file" slot-scope="obj">
									<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">
										{{ !$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : '' }}
									</a>
								</span>
								<span slot="fileRemark" slot-scope="text, obj">
									<a-input type="textarea" v-model="obj.note" :rows="4" :maxLength="300" />
								</span>
								<span slot="action" slot-scope="text, obj, supIndex">
									<a @click="DelSup(index, supIndex)">{{ $t('page.delete') }}</a>
								</span>
							</a-table>
						</a-descriptions-item>
						<a-descriptions-item>
							<UploadPreviewFile
								:uploadFilePath.sync="insurInfo.supplementFiles"
								:folderPath="$route.query.id"
								:isPreviewFile="false"
							></UploadPreviewFile>
						</a-descriptions-item>
					</a-descriptions>
				</template>
			</a-tab-pane>
		</a-tabs>
		<div class="ant-descriptions-title">Insurance Endorsement</div>
		<a-tabs :activeKey="carActiveKey" type="card" :animated="true" style="margin-top: 20px" @change="changeCarActiveKey">
			<a-tab-pane :tab="carInfo.ntsa.registrationNumber + ' Insurance Endorsement'" v-for="(carInfo, carIndex) in carInfos" :key="carIndex">
				<a-button style="margin-top: 10px" type="primary" @click="addEndorsement(carIndex)" v-if="addedIpf">
					Add {{ carInfo.ntsa.registrationNumber }} Insurance Endorsement</a-button
				>
				<a-tabs :defaultActiveKey="0" type="editable-card" :animated="true" style="margin-top: 20px" @edit="removeEndorsement" hideAdd>
					<a-tab-pane
						:tab="'Insurance Endorsement ' + (carInfo.endorsements.length - index)"
						v-for="(endor, index) in carInfo.endorsements"
						:key="index"
						:closable="endor.newInput"
					>
						<template v-if="endor.newInput">
							<a-descriptions bordered size="default">
								<a-descriptions-item label="Insurance Endorsement Letter (optional)" :span="3">
									<UploadPreviewFile :uploadFilePath.sync="endor.endorsementLetters" :folderPath="$route.query.id"></UploadPreviewFile>
								</a-descriptions-item>
								<a-descriptions-item label="Expiry Date" :span="3">
									<a-date-picker :valueFormat="dateFormatVal" type="date" v-model="endor.expireDate" showTime />
								</a-descriptions-item>
							</a-descriptions>
						</template>
						<template v-else>
							<a-descriptions bordered size="default">
								<a-descriptions-item label="Insurance Endorsement Letter (optional)" :span="3">
									<UploadPreviewFile
										:uploadFilePath.sync="endor.endorsementLetters"
										:folderPath="$route.query.id"
										:isUploadFileBtn="false"
									></UploadPreviewFile>
								</a-descriptions-item>
								<a-descriptions-item label="Expiry Date" :span="3">
									<span v-if="$Util.isEmpty(endor.expireDate)">
										<a-date-picker :valueFormat="dateFormatVal" type="date" v-model="expireDate[Number(`${carIndex}${index}`)]" showTime />
										<a-button style="margin-left: 20px" type="primary" @click="updateSupplement(endor, carIndex, index)">{{
											$t('operate.save')
										}}</a-button>
									</span>
									<span v-else>{{ endor.expireDate }}</span>
								</a-descriptions-item>
							</a-descriptions>
						</template>
					</a-tab-pane>
				</a-tabs>
			</a-tab-pane>
		</a-tabs>
		<a-button block style="margin-top: 10px" type="primary" @click="ipfSave">{{ $t('operate.save') }}</a-button>
	</div>
	<!-- 保险信息 end -->
</template>
<script>
import { apiIpfSave, apiSupplement, apiIpfApplyDetail, apiIpfTryCalculate } from '@/api/insuranceRenewal'
import ShowDeductionModal from '@/views/businessComponents/show-deduction-modal.vue'
import EditDeductionModal from '@/views/businessComponents/edit-deduction-modal.vue'

export default {
	components: {
		ShowDeductionModal,
		EditDeductionModal
	},
	data() {
		return {
			dateFormatVal: 'YYYY-MM-DD HH:mm:ss',
			addedIpf: false,
			insuranceData: [],
			carInfos: [],
			expireDate: [],
			carActiveKey: 0,
			ipfActiveKey: '0',
		}
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					scopedSlots: { customRender: 'fileName' },
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					scopedSlots: { customRender: 'fileRemark' },
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 100
				}
			]
		}
	},
	methods: {
		Init() {
			apiIpfApplyDetail(this.$route.query.id, false).then((res) => {
				this.insuranceData = res.loanInsuranceInfos.map((item) => {
					item.insuranceInterest = (item.insuranceInterest * 100).toFixed(1)
					if (!item.supplementFiles) {
						item.supplementFiles = []
					}
					return item
				})
				this.carInfos = res.disburseInfo.carInfos
				this.addedIpf =
					this.insuranceData.findIndex((insur) => {
						return insur.newInput === true
					}) !== -1
			})

		},
		addIPF() {
			this.insuranceData.unshift({
				loanId: this.$route.query.id,
				hasInsurance: 1,
				insuranceAmount: 0,
				insuranceInterest: 4,
				insuranceInstallments: 3,
				insuranceInstallmentPrincipal: 0,
				insuranceInstallmentInterest: 0,
				supplementFiles: [],
				newInput: true,
				delete: false,
				toBeDeducedUpfront: null,
				collectedUpfront: 0,
				upfrontSwitch: 0
			})
			this.addedIpf = true
		},
		removeIPF(targetKey, action) {
			if (action === 'remove') {
				if (this.insuranceData[0].delete === false) {
					this.insuranceData.shift()
				} else {
					this.insuranceData[0].delete = true
					let endorsements = []
					this.carInfos.forEach((carInfo) => {
						carInfo.endorsements.forEach((endor) => {
							if (endor.newInput) {
								endor.delete = true
								endorsements.push(endor)
							}
						})
					})
					apiIpfSave({
						loanInsuranceInfo: this.insuranceData[0],
						endorsements: endorsements
					}).then(() => {
						this.$message.success('Delete Success')
						location.reload()
					})
				}
				this.addedIpf = false
			}
		},
		addEndorsement(carIndex) {
			this.carInfos[carIndex].endorsements.unshift({
				insuranceId: this.addedIpf ? this.insuranceData[0].id : '',
				disburseCarId: this.carInfos[carIndex].id,
				endorsementLetters: [],
				expireDate: '',
				newInput: true,
				delete: false
			})
		},
		changeCarActiveKey(key) {
			this.carActiveKey = key
		},
		removeEndorsement(targetKey, action) {
			if (action === 'remove') {
				if (this.carInfos[this.carActiveKey].endorsements[targetKey].delete === false) {
					this.carInfos[this.carActiveKey].endorsements.splice(targetKey, 1)
				} else {
					this.carInfos[this.carActiveKey].endorsements[targetKey].delete = true
					let endorsements = []
					this.carInfos.forEach((carInfo) => {
						carInfo.endorsements.forEach((endor) => {
							if (endor.newInput) {
								endorsements.push(endor)
							}
						})
					})
					apiIpfSave({
						loanInsuranceInfo: this.insuranceData[0],
						endorsements: endorsements
					}).then(() => {
						this.$message.success('Delete Success')
						location.reload()
					})
				}
			}
		},
		getInsTryCalculate(value) {
			const params = {
				loanId: this.$route.query.id,
				insuranceAmount: this.insuranceData[0].insuranceAmount,
				insuranceInterest: this.insuranceData[0].insuranceInterest / 100,
				insuranceInstallments: this.insuranceData[0].insuranceInstallments,
			}
			if (this.insuranceData[0].hasOwnProperty('toBeDeducedUpfront')) {
				params.toBeDeducedUpfront = this.insuranceData[0].toBeDeducedUpfront
			}
			if (this.insuranceData[0].hasOwnProperty('collectedUpfront')) {
				params.collectedUpfront = this.insuranceData[0].collectedUpfront
			}
			if (this.insuranceData[0].hasOwnProperty('id')) {
				params.id = this.insuranceData[0].id
			}
			if (this.insuranceData[0].hasOwnProperty('upfrontSwitch')) {
				params.upfrontSwitch = this.insuranceData[0].upfrontSwitch
			}
			if (this.insuranceData[0].hasOwnProperty('hasInsurance')) {
				params.hasInsurance = this.insuranceData[0].hasInsurance
			}
			if (!this.insuranceData[0].insuranceAmount) {
				return false
			}
			if (value === true) {
				params.loanProcessingFee = null
				params.exciseDuty = null
			} else {

			}
			apiIpfTryCalculate(params).then((res) => {
				this.insuranceData[0].insuranceInstallmentPrincipal = res.insuranceInstallmentPrincipal
				this.insuranceData[0].insuranceInstallmentInterest = res.insuranceInstallmentInterest
				this.insuranceData[0].netDisbursementAmount = res.netDisbursementAmount
			})
		},
		updateSupplement(endor, carIndex, index) {
			apiSupplement({
				id: endor.id, //endorsement id
				expireDate: this.expireDate[Number(`${carIndex}${index}`)] //补充的过期日期
			}).then(() => {
				this.carInfos[carIndex].endorsements[index].expireDate = this.expireDate[Number(`${carIndex}${index}`)]
				this.$message.success('success')
			})
		},
		ipfSave() {
			if (!this.addedIpf) {
				return false
			}
			this.insuranceData[0].insuranceInterest = this.insuranceData[0].insuranceInterest / 100
			let endorsements = []
			this.carInfos.forEach((carInfo) => {
				carInfo.endorsements.forEach((endor) => {
					if (endor.newInput) {
						endorsements.push(endor)
					}
				})
			})
			apiIpfSave({
				loanInsuranceInfo: this.insuranceData[0],
				endorsements: endorsements
			}).then(() => {
				this.$message.success('success')
				this.closeTab(this.$route.fullPath)
				this.$router.push('/home/insuranceRenewal/renewalList')
			})
		},
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
			this.$store.commit('tabs/setActiveTab', '/home/insuranceRenewal/renewalList')
		},
		// 补充文件删除
		DelSup(idx, supIndex) {
			this.insuranceData[idx].supplementFiles.splice(supIndex, 1)
		}
	},
	created() {
		this.$nextTick(() => {
			this.Init()
		})
	}
}
</script>
<style lang="less">
.insurance-form {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.ant-form-item {
		width: 50%;
	}
	// .ant-input-number,
	// .ant-input,
	// .ant-select {
	// 	width: 200px;
	// }
}
.insurDesc {
	.ant-descriptions-item-content {
		padding: 0;
		.embedDesc {
			margin: 0;
			padding: 0;
			.ant-descriptions-view {
				border: none;
			}
			.ant-descriptions-row {
				.ant-descriptions-item {
					padding-bottom: 0;
					.ant-descriptions-item-label {
						min-width: 250px;
					}
					.ant-descriptions-item-content {
						padding: 0 24px;
					}
				}
			}
		}
	}
}

.table-descriptions {
	.ant-descriptions-row {
		border-bottom: none;
		padding: 0;
	}
	.ant-descriptions-item-label {
		display: none;
	}
	.ant-descriptions-item-content {
		width: 100%;
		padding: 0;
	}
	.ant-table table {
		width: -webkit-fill-available;
	}
	.ant-descriptions-view {
		border: none;
	}
}

.ipf-yes-descriptions {
	display: flex;

	.ipf-wrapper {
		margin: 20px 0;
		width: 200px;
		border: 1px solid #e8e8e8;
		border-right: 0;
		border-radius: 6px 0 0 6px;
	}

	.ant-descriptions-view {
		border-radius: 0px 6px 6px 0px;
	}
}
</style>
